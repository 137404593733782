<template>
    <div class="background">
        <!-- Отрисовка выигрышного номера -->
        <img v-if="isNumber37"
             :src="win37HoverImage"
             :style="result37PositionsStyle"
             class="result__number"
        />
        <img v-else
             :src="winNumberHoverImage"
             :style="resultPositionsStyle"
             class="result__number"
        />

        <div v-for="(bet, index) in combineBetsAndWins"
             :key="index"
             :style="chipPositionsStyle(bet.pos)"
             class="stake"
        >
            <bingo37-english-chip :bet="bet.bet"
                                  :denom="stateData.denom"
                                  :win="bet.win"
            />
        </div>
    </div>
</template>

<script>
import Bingo37EnglishChip from "./Bingo37EnglishChip";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    roundResult: {
                        resultNumber: null,
                        bets: [],
                        winBets: [],
                    },
                    denom: null
                }
            }
        }
    },
    data() {
        return {
            cell: {
                width: 90,
                height: 114,
                bottomHeight: 72
            },
            chip: {
                size: 46
            }
        }
    },
    computed: {
        isNumber37() {
            return this.stateData.roundResult.resultNumber === 37;
        },
        winNumberHoverImage() {
            return require('@/assets/games/bingo/bingo37english/win.png');
        },
        win37HoverImage() {
            return require('@/assets/games/bingo/bingo37english/win_37.png');
        },
        resultPositionsStyle() {
            return {
                left: `${this.resultLeft}px`,
                top: `${this.resultTop}px`,
            }
        },
        result37PositionsStyle() {
            return {
                left: '0px',
                top: '0px',
            }
        },
        resultLeft() {
            return (this.cell.width * 0.81 + Math.floor((this.stateData.roundResult.resultNumber - 1) / 3) * this.cell.width);
        },
        resultTop() {
            return (this.cell.height * 2 - ((this.stateData.roundResult.resultNumber - 1) % 3) * this.cell.height);
        },
        combineBetsAndWins() {
            let betsAndWins = [];

            let bets = this.stateData.roundResult.bets;
            let winBets = this.stateData.roundResult.winBets;

            for (let i = 0; i < bets.length; i++) {
                betsAndWins.push({
                        ...{
                            ...bets[i], ...{
                                win: 0,
                                code: 0,
                                coefficient: 0
                            }
                        },
                        ...(winBets.find((itmInner) => itmInner.code === bets[i].pos))
                    }
                );
            }

            return betsAndWins;
        }
    },
    methods: {
        chipPositionsStyle(code) {
            let left = 0, top = 0;

            // Основные ставки
            if (code >= 1 && code <= 36) {
                left = this.cell.width / 2 + this.cell.width * Math.floor((code - 1) / 3);
                top = this.cell.height * 3 - this.cell.height / 2 - ((code - 1) % 3) * this.cell.height;
            }
            //нижний ряд
            if (code >= 52 && code <= 75) {
                left = (code - 52) * this.cell.width / 2;
                top = this.cell.height * 3;
            }
            //третий ряд снизу
            if (code >= 88 && code <= 111) {
                left = (code - 88) * this.cell.width / 2;
                top = this.cell.height * 2;
            }
            //пятый ряд снизу
            if (code >= 124 && code <= 147) {
                left = (code - 124) * this.cell.width / 2;
                top = this.cell.height;
            }

            //второй ряд снизу (сплиты  ряда от 1 до 34)
            if (code >= 76 && code <= 87) {
                left = (code - 76) * this.cell.width;
                top = this.cell.height * 2.5;
            }

            //четвертый ряд снизу (сплиты  ряда от 2 до 35)
            if (code >= 112 && code <= 123) {
                left = (code - 112) * this.cell.width;
                top = this.cell.height * 1.5;
            }

            //шестой ряд снизу (сплиты  ряда от 2 до 35)
            if (code >= 148 && code <= 159) {
                left = (code - 148) * this.cell.width;
                top = this.cell.height * 0.5;
            }

            //1st 12, 2nd 12, 3rd 12
            if (code >= 44 && code <= 46) {
                left = this.cell.width * 2 + (code - 44) * this.cell.width * 4;
                top = this.cell.height * 3 + this.cell.bottomHeight / 2;
            }

            //line of 2:1
            if (code >= 47 && code <= 49) {
                left = this.cell.width * 12.1 + this.cell.width / 3;
                top = this.cell.height * 0.5 + this.cell.height * (code - 47);
            }

            switch (Number(code)) {
                case 37: //37
                    left = this.cell.width * (-0.4);
                    top = this.cell.height * 1.5;
                    break;
                case 50: //1 to 18
                    left = this.cell.width;
                    top = this.cell.height * 3 + this.cell.bottomHeight * 1.5;
                    break;
                case 42: //Even
                    left = this.cell.width * 3;
                    top = this.cell.height * 3 + this.cell.bottomHeight * 1.5;
                    break;
                case 41: //Red
                    left = this.cell.width * 5;
                    top = this.cell.height * 3 + this.cell.bottomHeight * 1.5;
                    break;
                case 40: //Black
                    left = this.cell.width * 7;
                    top = this.cell.height * 3 + this.cell.bottomHeight * 1.5;
                    break;
                case 43: //Odd
                    left = this.cell.width * 9;
                    top = this.cell.height * 3 + this.cell.bottomHeight * 1.5;
                    break;
                case 51: //19 to 36
                    left = this.cell.width * 11;
                    top = this.cell.height * 3 + this.cell.bottomHeight * 1.5;
                    break;
            }

            return {
                left: `${left}px`,
                top: `${top}px`,
            }
        }
    },
    mounted() {
        this.$store.dispatch('workspace/setHeight', 488);
        this.$store.dispatch('workspace/setWidth', 1227);
    },
    components: {
        Bingo37EnglishChip
    },
}
</script>

<style scoped>
.background {
    background-image: url('../../../../../assets/games/bingo/bingo37english/bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 1227px;
    height: 488px;
}

.result__number {
    position: absolute;
}

.stake {
    position: absolute;
    width: 46px;
    height: 46px;
    margin-top: -23px;
    margin-left: 51px;
}

.chip {
    width: 46px;
    height: 46px;
}

.chip__text {
    font-weight: bold;
    color: white;
    cursor: help;

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
